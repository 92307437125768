import { useAuth0 } from '@auth0/auth0-react';
import { GlobalHeader } from '@deepup/global-header';
import { Box, styled } from '@mui/material';
import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import type { Language } from '@src/i18n.config';
import { trackEvent } from '@utils/trackEvent';

const Container = styled(Box)(() => ({
  minHeight: '100vh',
}));

const Wrapper = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  maxWidth: theme.spacing(124),
}));

export const Layout: FC = () => {
  const { isAuthenticated, user } = useAuth0();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    trackEvent('userEmailDomain', {
      email_domain: user?.email ? (user.email.split('@')[1] ?? 'unknown') : 'unknown',
    });
  }, [user]);

  return (
    <>
      {isAuthenticated && (
        <GlobalHeader<Language>
          language={i18n.language as Language}
          languageSelectors={[{ key: 'de' }, { key: 'en' }]}
          onChangeLanguage={(selectedLanguage: string) => {
            i18n.changeLanguage(selectedLanguage);
          }}
          showAppMenu
          showLanguageToggle
          showThemeToggle
          title={t('title')}
        />
      )}
      <Container pt={10} px={{ xs: 2, sm: 8 }}>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Container>
    </>
  );
};
