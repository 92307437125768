import {
  Cockpit as CockpitIcon,
  ConMon as ConMonIcon,
  OrganizationManagement as OrganizationManagementIcon,
  DeviceHub as DeviceManagementIcon,
  InvoiceToolIcon,
} from '@deepup/icons';
import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppAuthorizationApi } from '@hooks/api/useAppAuthorizationApi';
import { useEnvironment } from '@hooks/useEnvironment';
import type { AppInfo } from '@typings/AppInfo';
import { isAppUnauthorized } from '@utils/appAuthorization';

import { AppGallery } from './AppGallery';
import { Heading } from './Heading';

export const Home = () => {
  const { t } = useTranslation();
  const { cockpitUrl, omaUrl, conmonUrl, deviceManagementUrl, invoiceToolUrl } = useEnvironment();
  const { data: appAuthorizationData, isLoading } = useAppAuthorizationApi();

  if (isLoading && !appAuthorizationData) {
    return (
      <Stack alignItems="center" height="80vh" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const appsInfo: AppInfo[] = [
    {
      id: 'construction-monitoring',
      name: t('entities.apps.constructionMonitoring.title'),
      Icon: ConMonIcon,
      description: t('entities.apps.constructionMonitoring.description'),
      url: conmonUrl,
      isUnauthorized: isAppUnauthorized('construction-monitoring', appAuthorizationData),
    },
    {
      id: 'cockpit',
      name: t('entities.apps.cockpit.title'),
      Icon: CockpitIcon,
      description: t('entities.apps.cockpit.description'),
      url: cockpitUrl,
      isUnauthorized: isAppUnauthorized('cockpit', appAuthorizationData),
      isDeprecated: true,
    },
    {
      id: 'organization-management',
      name: t('entities.apps.organizationManagement.title'),
      Icon: OrganizationManagementIcon,
      description: t('entities.apps.organizationManagement.description'),
      url: omaUrl,
      isUnauthorized: isAppUnauthorized('organization-management', appAuthorizationData),
    },
    {
      id: 'device-management',
      name: t('entities.apps.deviceManagement.title'),
      Icon: DeviceManagementIcon,
      description: t('entities.apps.deviceManagement.description'),
      url: deviceManagementUrl,
      isUnauthorized: isAppUnauthorized('device-management', appAuthorizationData),
    },
    {
      id: 'invoice-tool',
      name: t('entities.apps.invoiceTool.title'),
      Icon: InvoiceToolIcon,
      description: t('entities.apps.invoiceTool.description'),
      url: invoiceToolUrl,
      isUnauthorized: isAppUnauthorized('invoice-tool', appAuthorizationData),
      isAlpha: true,
    },
  ];

  const visibleApps = appsInfo.filter((appInfo) =>
    appAuthorizationData?.find(({ id }) => id === appInfo.id),
  );

  return (
    <Stack py={{ xs: 3, sm: 8 }}>
      <Heading />
      <AppGallery appsInfo={visibleApps} />
    </Stack>
  );
};
