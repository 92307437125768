import { Box, Typography, useTheme } from '@mui/material';

export const DeprecatedBadge = () => {
  const theme = useTheme();

  return (
    <Box position="absolute" right={0} top={0}>
      <Box
        sx={{
          transform: 'rotate(45deg) translate(95px, -50px)',
          backgroundColor: theme.palette.warning.main,
          padding: '5px 100px',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          DEPRECATED
        </Typography>
      </Box>
    </Box>
  );
};
